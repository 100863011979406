import { AdditionalAnswer } from './applicationAnswer';
import { ApplicationAttachment } from './applicationAttachment';
import { SponsorshipOpportunityQuestion } from './applicationQuestion';
import { CommonApplicationProfile } from './commonApplicationProfile';
import { LocationPreference } from './location';
import { SponsorshipApplicationStatus, SponsorshipApplicationSubstatus } from './sponsorshipApplicationStatuses';
import { SponsorshipOpportunityType } from './sponsorshipOpportunity';

export enum SponsorshipApplicationOverview {
  ARCHIVED = 'archived',
  ONGOING = 'ongoing',
  REQUIRES_ACTION = 'requires-action',
}

export interface StoredFile {
  id: string;
  name: string;
  size: number;
  url: string;
  file_label: string | null;
}

export interface Interview {
  interview_invitation_date_time?: string;
  interview_scheduled_date_time?: string;
  sponsorship_application_id: string;
}

export interface SponsorshipApplicationFiles {
  [key: string]: StoredFile | null;
}

/**
 * Represents the base structure for sponsorship applications.
 * Provides common identification and status tracking fields used across various interfaces.
 */
export interface SponsorshipApplicationBase {
  id: string;
  additional_answers: AdditionalAnswer[];
  interviews?: Interview[];
  requires_interview: boolean;
  status: SponsorshipApplicationStatus;
  submitted_at: string;
  substatus: SponsorshipApplicationSubstatus;
  uploaded_files: SponsorshipApplicationFiles;

  // Common Application Profile
  institution: CommonApplicationProfile['institution'];
  major: CommonApplicationProfile['major'];

  // Opportunity
  derived_application_type: SponsorshipOpportunityType;
  location_preferences: LocationPreference[];
  opportunity_display_name: string;
  opportunity_id: string;
  opportunity_ordered_questions: SponsorshipOpportunityQuestion[];
  opportunity_required_documents: ApplicationAttachment[];
  opportunity_optional_documents: ApplicationAttachment[];
  show_location_preferences: boolean;

  // Partner
  partner_name: string;
  partner_uid: string;
}

/**
 * Provides a comprehensive model for internal management use.
 * It merges base application data, extended profile information and additional fields for operational and workflow management.
 */
export interface SponsorshipApplication extends SponsorshipApplicationBase {
  allowed_status_transitions: SponsorshipApplicationStatus[];
  citizenship_status: CommonApplicationProfile['citizenship_status'];
  degree_type: CommonApplicationProfile['degree_type'];
  expected_graduation_date: CommonApplicationProfile['expected_graduation_date'];
  student_email: CommonApplicationProfile['student_email'];
  student_first_name: CommonApplicationProfile['student_first_name'];
  student_last_name: CommonApplicationProfile['student_last_name'];
  student_name: CommonApplicationProfile['student_name'];
  student_phone_number: CommonApplicationProfile['student_phone_number'];
}

/**
 * Extends the base sponsorship application information specifically for student use.
 * It incorpores additional details relevant for form rendering and student interaction flows.
 */
export interface StudentSponsorshipApplication extends SponsorshipApplicationBase {
  envelope_id: string | null;
  opportunity_id: string;
}

/**
 * Provides a light model for retrieving multiple Sponsorship Applications.
 */
export interface LightSponsorshipApplication {
  id: string;
  expected_graduation_date: CommonApplicationProfile['expected_graduation_date'];
  institution: CommonApplicationProfile['institution'];
  major: CommonApplicationProfile['major'];
  partner_name: string;
  status: SponsorshipApplicationStatus;
  student_name: CommonApplicationProfile['student_name'];
  submitted_at: string;
  substatus: SponsorshipApplicationSubstatus;
}

export enum SponsorshipApplicationFinacingEnum {
  BLENDED = 'BLENDED',
  NEEDS_FINANCING = 'NEEDS_FINANCING',
  ONLY_REPAYMENT = 'ONLY_REPAYMENT',
  UNKNOWN = 'UNKNOWN',
}

export type SponsorshipApplicationFinancing =
  SponsorshipApplicationFinacingEnum.BLENDED |
  SponsorshipApplicationFinacingEnum.NEEDS_FINANCING |
  SponsorshipApplicationFinacingEnum.ONLY_REPAYMENT |
  SponsorshipApplicationFinacingEnum.UNKNOWN;
